import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  // mode: "history",
  base: "/",
  routes: [
    // {
    //   path: "",
    //   redirect: "/dashboard",
    // },
    {
      path: "/eduction/:id/player",
      name: "user.eduction-player",
      component: () => import("@/view/User/EducationPlayer"),
    },
    {
      path: "/dashboard",
      redirect: "/dashboard",
      mode: "history",
      component: () => import("@/view/layouts/DashboardLayout"),
      children: [
        {
          path: "/test",
          component: () => import("@/view/test/Test"),
        },
        {
          path: "/dashboard",
          name: "user.index",
          component: () => import("@/view/User/Index.vue"),
        },
        {
          path: "/user/:id/show",
          name: "user.show",
          component: () => import("@/view/User/UserShow"),
        },
        {
          path: "/profile",
          name: "user.profile",
          component: () => import("@/view/User/Profile"),
        },
        {
          path: "/update-profile",
          name: "user.update-profile",
          component: () => import("@/view/User/UpdateProfile"),
        },
        /*   {
                      path: "/company-profile",
                      name: "user.company-profile",
                      component: () => import("@/view/User/CompanyProfile"),
                  }, */
        {
          path: "/my-education",
          name: "user.my-education",
          component: () => import("@/view/User/MyAssignments"),
        },
        {
          path: "/my-assignment",
          name: "user.my-assignment",
          component: () => import("@/view/User/MyAssignments"),
        },
        {
          path: "/providers-trainings/:provider",
          name: "user.providers-trainings",
          component: () => import("@/view/User/ProviderResults"),
        },
        {
          path: "/providers-trainings/",
          name: "user.providers-trainings",
          component: () => import("@/view/User/ProviderResults"),
        },
        {
          path: "/providers-trainings",
          name: "user.providers-trainings",
          component: () => import("@/view/User/MyProvidersAssignments"),
        },
        {
          path: "/assignment/:id/detail",
          name: "user.assignment-detail",
          component: () => import("@/view/User/AssignmentDetails"),
        },
        {
          path: "/my-statistics",
          name: "user.my-statistics",
          component: () => import("@/view/User/MyStatistics"),
        },
        {
          path: "/duel-result",
          name: "user.duel-result",
          component: () => import("@/view/User/MyStatistics"),
        },
        {
          path: "/education/:id/detail",
          name: "user.eduction-detail",
          component: () => import("@/view/User/EducationDetails"),
        },
        {
          path: "/education-category",
          name: "user.education-category",
          component: () => import("@/view/User/EducationCatalog"),
        },
        {
          path: "/search",
          name: "User.search",
          component: () => import("@/view/User/Search"),
        },
        {
          path: "/notification",
          name: "dashboard.notification",
          props: true,
          component: () => import("@/view/User/Notification"),
        },
        {
          path: "/duel",
          name: "User.duel",
          component: () => import("@/view/User/Duel"),
        },
        {
          path: "/wall",
          name: "user.wall",
          props: true,
          component: () => import("@/view/User/Wall"),
        },
        {
          path: "/gift",
          name: "user.gift",
          component: () => import("@/view/User/Gift"),
        },
        {
          path: "/my-collection",
          name: "user.my-collection",
          component: () => import("@/view/User/MyCollection"),
        },
        {
          path: "/collection/:id/detail",
          name: "user.collection-detail",
          component: () => import("@/view/User/CollectionDetails"),
        },
        {
          path: "/messages",
          name: "User.messages",
          component: () => import("@/view/User/Messages"),
        },
        {
          path: "/my-certification",
          name: "User.my-certification",
          component: () => import("@/view/User/MyCertificates"),
        },
        {
          path: "/agenda",
          name: "user.agenda",
          component: () => import("@/view/User/Agenda"),
        },
        {
          path: "/certification/:id/preview",
          name: "user.certification-preview",
          component: () => import("@/view/User/CertificatesPreview"),
        },
        {
          path: "/faq",
          name: "User.faq",
          component: () => import("@/view/User/FAQ"),
        },
        {
          path: "/legal-notification",
          name: "User.agreement",
          component: () => import("@/view/User/Agreement"),
        },
      ],
    },
    {
      path: "/admin",
      redirect: "/admin/dashboard",
      mode: "history",
      component: () => import("@/view/company/layouts/DashboardLayout"),
      children: [
        {
          path: "/admin/dashboard",
          name: "company.dashboard",
          component: () => import("@/view/company/Dashboard"),
        },
        {
          path: "/admin/user",
          name: "company.user.index",
          component: () => import("@/view/company/User/Users"),
        },
        {
          path: "/admin/user/list",
          name: "company.user.list",
          component: () => import("@/view/company/User/Users"),
        },
        {
          path: "/admin/user/add",
          name: "company.user.add",
          component: () => import("@/view/company/User/UserAdd"),
        },
        {
          path: "/admin/user/edit/:id",
          name: "company.user.edit",
          component: () => import("@/view/company/User/UserEdit"),
        },
        {
          path: "/admin/user/group",
          name: "company.user.group",
          component: () => import("@/view/company/User/Group/List"),
        },
        {
          path: "/admin/user/group/add",
          name: "company.user.group.add",
          component: () => import("@/view/company/User/Group/Add"),
        },
        {
          path: "/admin/user/group/edit/:id",
          name: "company.user.group.edit",
          component: () => import("@/view/company/User/Group/Edit"),
        },
        {
          path: "/admin/user/passive",
          name: "company.user.passive.list",
          component: () => import("@/view/company/User/UserPassive"),
        },
        {
          path: "/admin/content/add",
          name: "company.content.add",
          component: () => import("@/view/company/Content/ContentAdd"),
        },
        {
          path: "/admin/education/catalog/add",
          name: "company.content.catalog.add",
          component: () =>
            import("@/view/company/CatalogEducation/CatalogContentAdd"),
        },
        {
          path: "/admin/education/catalog/edit/:id",
          name: "company.education.catalog.edit",
          component: () =>
            import("@/view/company/CatalogEducation/CatalogContentEdit"),
        },
        {
          path: "/admin/content",
          name: "company.contents.all",
          component: () => import("@/view/company/Content/Content"),
        },
        {
          path: "/admin/content/edit/:id",
          name: "company.contents.edit",
          component: () => import("@/view/company/Content/ContentEdit"),
        },
        {
          path: "/admin/content/:id/preview",
          name: "company.contents.preview",
          component: () => import("@/view/company/Content/Content"),
        },
        {
          path: "/admin/content/:id/assignment",
          name: "company.contents.assignment",
          component: () => import("@/view/company/Content/Content"),
        },
        {
          path: "/admin/program",
          name: "company.programs.all",
          component: () => import("@/view/company/Program/List"),
        },
        {
          path: "/admin/program/add",
          name: "company.program.add",
          component: () => import("@/view/company/Program/Add"),
        },
        {
          path: "/admin/program/edit/:id",
          name: "company.programs.edit",
          component: () => import("@/view/company/Program/Edit"),
        },
        {
          path: "/admin/settings",
          name: "company.setting",
          component: () => import("@/view/company/Settings"),
        },
        {
          path: "/admin/data",
          name: "company.data",
          component: () => import("@/view/company/Data"),
        },
        {
          path: "/admin/companies",
          name: "company.companies.list",
          component: () => import("@/view/company/Companies/List"),
        },
        {
          path: "/admin/companies/add",
          name: "company.companies.add",
          component: () => import("@/view/company/Companies/Add"),
        },
        {
          path: "/admin/companies/edit/:id",
          name: "company.companies.edit",
          component: () => import("@/view/company/Companies/Edit"),
        },
        {
          path: "/admin/institutions",
          name: "company.institutions.list",
          component: () => import("@/view/company/Institution/List"),
        },
        {
          path: "/admin/institutions/add",
          name: "company.institutions.add",
          component: () => import("@/view/company/Institution/Add"),
        },
        {
          path: "/admin/institutions/edit/:id",
          name: "company.institutions.edit",
          component: () => import("@/view/company/Institution/Edit"),
        },
        {
          path: "/admin/question/add",
          name: "company.question.add",
          component: () => import("@/view/company/Question/Add"),
        },
        {
          path: "/admin/question/edit/:id",
          name: "company.question.edit",
          component: () => import("@/view/company/Question/Edit"),
        },
        {
          path: "/admin/questions",
          name: "company.questions.list",
          component: () => import("@/view/company/Question/List"),
        },
        {
          path: "/admin/surveys",
          name: "company.surveys.list",
          component: () => import("@/view/company/Survey/List"),
        },
        {
          path: "/admin/survey/add",
          name: "company.survey.add",
          component: () => import("@/view/company/Survey/Add"),
        },
        {
          path: "/admin/survey/:id/edit",
          name: "company.survey.edit",
          component: () => import("@/view/company/Survey/Edit"),
        },
        {
          path: "/admin/evaluation-surveys",
          name: "company.evaluation-surveys.list",
          component: () => import("@/view/company/EvaluationSurvey/List"),
        },
        {
          path: "/admin/evaluation-survey/add",
          name: "company.evaluation-survey.add",
          component: () => import("@/view/company/EvaluationSurvey/Add"),
        },
        {
          path: "/admin/evaluation-survey/:id/edit",
          name: "company.evaluation-survey.edit",
          component: () => import("@/view/company/EvaluationSurvey/Edit"),
        },
        {
          path: "/admin/exams",
          name: "company.exams.list",
          component: () => import("@/view/company/Exam/List"),
        },
        {
          path: "/admin/exam/add",
          name: "company.exam.add",
          component: () => import("@/view/company/Exam/Add"),
        },
        {
          path: "/admin/exam/add-demo",
          name: "company.exam.add-demo",
          component: () => import("@/view/company/Exam/Add-demo"),
        },
        {
          path: "/admin/exam/:id/edit-demo",
          name: "company.exam.edit-demo",
          component: () => import("@/view/company/Exam/Edit-demo"),
        },
        {
          path: "/admin/exam/:id/edit",
          name: "company.exam.edit",
          component: () => import("@/view/company/Exam/Edit"),
        },
        {
          path: "/admin/certificates",
          name: "company.certificate.list",
          component: () => import("@/view/company/Certificates/List"),
        },
        {
          path: "/admin/certificate/add",
          name: "company.certificate.add",
          component: () => import("@/view/company/Certificates/Add"),
        },
        {
          path: "/admin/certificate/:id/edit",
          name: "company.certificate.edit",
          component: () => import("@/view/company/Certificates/Edit"),
        },
        {
          path: "/admin/hashtags",
          name: "company.hashtags.list",
          component: () => import("@/view/company/Hashtags/List"),
        },
        {
          path: "/admin/hashtags/add",
          name: "company.hashtags.add",
          component: () => import("@/view/company/Hashtags/Add"),
        },
        {
          path: "/admin/hashtags/:id/edit",
          name: "company.hashtags.edit",
          component: () => import("@/view/company/Hashtags/Edit"),
        },
        {
          path: "/admin/certificate-templates",
          name: "company.certificate_template.list",
          component: () => import("@/view/company/CertificateTemplates/List"),
        },
        {
          path: "/admin/certificate-template/add",
          name: "company.certificate_template.add",
          component: () => import("@/view/company/CertificateTemplates/Add"),
        },
        {
          path: "/admin/certificate-template/:id/edit",
          name: "company.certificate_template.edit",
          component: () => import("@/view/company/CertificateTemplates/Edit"),
        },
        {
          path: "/admin/points",
          name: "company.point.list",
          component: () => import("@/view/company/Points/List"),
        },
        {
          path: "/admin/point/:id/update",
          name: "company.point.update",
          component: () => import("@/view/company/Points/Edit"),
        },
        {
          path: "/admin/menu-modules",
          name: "company.menu_modules.list",
          component: () => import("@/view/company/Module/List"),
        },
        {
          path: "/admin/gifts",
          name: "company.gift.list",
          component: () => import("@/view/company/Gifts/List"),
        },
        {
          path: "/admin/gift/add",
          name: "company.gift.add",
          component: () => import("@/view/company/Gifts/Add"),
        },
        {
          path: "/admin/gift/:id/edit",
          name: "company.gift.edit",
          component: () => import("@/view/company/Gifts/Edit"),
        },
        {
          path: "/admin/gift/requests",
          name: "company.gift.requests",
          component: () => import("@/view/company/Gifts/Request"),
        },
        {
          path: "/admin/share-management",
          name: "company.management.share",
          component: () => import("@/view/company/ShareManagement/Shares"),
        },
        {
          path: "/admin/order-management",
          name: "company.management.order",
          component: () => import("@/view/company/OrderManagement/Orders"),
        },
        {
          path: "/admin/content-management",
          name: "company.management.content",
          component: () => import("@/view/company/ShareManagement/Content"),
        },
        {
          path: "/admin/mail-templates",
          name: "company.mail.templates",
          component: () => import("@/view/company/Mail/Template"),
        },
        {
          path: "/admin/assignments",
          name: "company.assignments.list",
          component: () => import("@/view/company/Assignments/List"),
        },
        {
          path: "/admin/auto-assignments",
          name: "company.assignments.auto",
          component: () => import("@/view/company/Assignments/Auto"),
        },
        {
          path: "/admin/auto-assignments/add",
          name: "company.assignments.addAuto",
          component: () => import("@/view/company/Assignments/AddAuto"),
        },
        {
          path: "/admin/auto-assignments/:id/edit",
          name: "company.assignments.editAuto",
          component: () => import("@/view/company/Assignments/EditAuto"),
        },
        {
          path: "/admin/assignment/add",
          name: "company.assignments.add",
          component: () => import("@/view/company/Assignments/Add"),
        },
        {
          path: "/admin/assignment/:id/edit",
          name: "company.assignments.edit",
          component: () => import("@/view/company/Assignments/Edit"),
        },
        {
          path: "/admin/assignment/:id/preview",
          name: "company.assignments.preview",
          component: () => import("@/view/company/Assignments/Preview"),
        },
        {
          path: "/admin/education/detail",
          name: "company.education.detail",
          component: () => import("@/view/company/Educations/Detail"),
        },
        {
          path: "/admin/advertisement",
          name: "company.advertisement.list",
          component: () => import("@/view/company/Advertisement/List"),
        },
        {
          path: "/admin/advertisement/add",
          name: "company.advertisement.add",
          component: () => import("@/view/company/Advertisement/Add"),
        },
        {
          path: "/admin/advertisement/:id/edit",
          name: "company.advertisement.edit",
          component: () => import("@/view/company/Advertisement/Edit"),
        },
        {
          path: "/admin/announcement",
          name: "company.announcement.list",
          component: () => import("@/view/company/Announcement/List"),
        },
        {
          path: "/admin/announcement/add",
          name: "company.announcement.add",
          component: () => import("@/view/company/Announcement/Add"),
        },
        {
          path: "/admin/announcement/:id/edit",
          name: "company.announcement.edit",
          component: () => import("@/view/company/Announcement/Edit"),
        },
        {
          path: "/admin/education/catalog",
          name: "company.education.catalog.list",
          component: () =>
            import("@/view/company/CatalogEducation/CatalogEducation"),
        },

        {
          path: "/admin/education/catalog/purchased",
          name: "company.education.catalog.purchased",
          component: () =>
            import("@/view/company/CatalogEducation/CatalogEducationPurchased"),
        },

        {
          path: "/admin/requests/user",
          name: "company.requests.user",
          component: () =>
            import("@/view/company/RequestsAndDemands/UserRequests"),
        },
        {
          path: "/admin/requests/training/:id",
          name: "company.requests.training",
          component: () =>
            import("@/view/company/RequestsAndDemands/EducationDetails"),
        },
        {
          path: "/admin/requests/purchase",
          name: "company.requests.purchase",
          component: () =>
            import("@/view/company/RequestsAndDemands/PurchaseRequests"),
        },
        {
          path: "/admin/reports/statistics",
          name: "company.reports.statistics",
          component: () => import("@/view/company/Reports/Statistics"),
        },
        {
          path: "/admin/reports/institutions",
          name: "company.reports.institutions",
          component: () => import("@/view/company/Reports/Institutions"),
        },
        {
          path: "/admin/reports/licences",
          name: "company.reports.licences",
          component: () => import("@/view/company/Reports/Licences"),
        },
        {
          path: "/admin/reports/certificates",
          name: "company.reports.certificates",
          component: () => import("@/view/company/Reports/Certificates"),
        },
        {
          path: "/admin/reports/survey-reports",
          name: "company.reports.surveyReports",
          component: () => import("@/view/company/Reports/SurveyResults"),
        },
        {
          path: "/admin/reports/evaluation-survey-reports",
          name: "company.reports.evaluationSurveyReports",
          component: () =>
            import("@/view/company/Reports/EvaluationSurveyResults"),
        },
        {
          path: "/admin/reports/education-end-general-evaluation-report",
          name: "company.reports.educationEndGeneralEvaluationReport",
          component: () =>
            import(
              "@/view/company/Reports/EducationEndGeneralEvaluationReport"
            ),
        },
        {
          path: "/admin/reports/education-end-detail-evaluation-report",
          name: "company.reports.educationEndDetailEvaluationReport",
          component: () =>
            import("@/view/company/Reports/EducationEndDetailEvaluationReport"),
        },
        {
          path: "/admin/reports/gifts",
          name: "company.reports.gifts",
          component: () => import("@/view/company/Reports/Gifts"),
        },
        {
          path: "/admin/reports/users",
          name: "company.reports.users",
          component: () => import("@/view/company/Reports/Users"),
        },
        {
          path: "/admin/reports/assignments",
          name: "company.reports.assignments",
          component: () => import("@/view/company/Reports/Assignments"),
        },

        {
          path: "/admin/reports/user-assignments",
          name: "company.reports.userAssignments",
          component: () => import("@/view/company/Reports/UserAssignment"),
        },
        {
          path: "/admin/reports/self-registiration",
          name: "company.reports.selfRegistiration",
          component: () => import("@/view/company/Reports/SelfRegistiration"),
        },
        {
          path: "/admin/reports/contents",
          name: "company.reports.contents",
          component: () => import("@/view/company/Reports/Contents"),
        },
        {
          path: "/admin/reports/user-contents",
          name: "company.reports.userContents",
          component: () => import("@/view/company/Reports/UserContent"),
        },
        {
          path: "/admin/reports/exams",
          name: "company.reports.exams",
          component: () => import("@/view/company/Reports/Exams"),
        },
        {
          path: "/admin/reports/exam-results",
          name: "company.reports.examResults",
          component: () => import("@/view/company/Reports/ExamResults"),
        },
        {
          path: "/admin/reports/linkedin-learning",
          name: "company.reports.linkedin-learning",
          component: () =>
            import("@/view/company/Reports/LinkedinLearning.vue"),
        },
        {
          path: "/admin/reports/udemy-reports",
          name: "company.reports.udemy-reports",
          component: () => import("@/view/company/Reports/UdemyReports.vue"),
        },
        {
          path: "/admin/reports/harvard-business-reports",
          name: "company.reports.harvard-business-reports",
          component: () =>
            import("@/view/company/Reports/HarvardBusinessReports.vue"),
        },
        {
          path: "/admin/reports/external-source-reports",
          name: "company.reports.external-source-reports",
          component: () =>
            import("@/view/company/Reports/ExternalSourceReports.vue"),
        },
        {
          path: "/admin/reports/pre-post-reports",
          name: "company.reports.pre-post-reports",
          component: () =>
            import("@/view/company/Reports/PrePostReports.vue"),
        },
        {
          path: "admin/faq",
          name: "company.faq",
          component: () => import("@/view/User/FAQ"),
        },
        {
          path: "faq/list",
          name: "company.faq.list",
          component: () => import("@/view/company/Faq/List"),
        },
        {
          path: "faq/add",
          name: "company.faq.add",
          component: () => import("@/view/company/Faq/Add"),
        },
        {
          path: "/admin/faq/:id/edit",
          name: "company.faq.edit",
          component: () => import("@/view/company/Faq/Edit"),
        },

        {
          path: "faq-categories/list",
          name: "company.faq_categories.list",
          component: () => import("@/view/company/FaqCategories/List"),
        },
        {
          path: "faq-categories/add",
          name: "company.faq_categories.add",
          component: () => import("@/view/company/FaqCategories/Add"),
        },
        {
          path: "/admin/faq-categories/:id/edit",
          name: "company.faq_categories.edit",
          component: () => import("@/view/company/FaqCategories/Edit"),
        },
        {
          path: "/admin/wall/:user",
          name: "company.user.wall",
          props: true,
          component: () => import("@/view/User/Wall"),
        },
        {
          path: "/admin/integrations",
          name: "company.integrations.list",
          component: () => import("@/view/company/Integrations/List"),
        },
        {
          path: "/user-statistics/:user",
          name: "company.user.statistics",
          component: () => import("@/view/User/MyStatistics"),
        },
        {
          path: "/assignments/:assignment/classroom-education",
          name: "company.user.classroom-education",
          component: () => import("@/view/User/ClassRoomEducation"),
        },
      ],
    },
    {
      name: "login",
      path: "/login",

      component: () => import("@/view/auth/Login"),
    },
    {
      path: "/confirm-access-code",
      name: "index.confirm-access-code",
      component: () => import("@/view/auth/ConfirmAccessCode"),
    },
    {
      path: "/forgot-password",
      name: "index.forgot-password",
      component: () => import("@/view/auth/ForgotPassword"),
    },
    {
      path: "/create-password",
      name: "index.create-password",
      component: () => import("@/view/auth/CreatePassword"),
    },
    {
      path: "/register",
      name: "index.register",
      component: () => import("@/view/auth/Register"),
    },
    {
      path: "/register-invitation/:params",
      name: "index.register-sign",
      component: () => import("@/view/auth/Register"),
    },
    {
      path: "/reset-password/:token",
      name: "index.reset-password",
      component: () => import("@/view/auth/ResetPassword"),
    },
    {
      // the 403 route, when access denied
      path: "/403",
      name: "403",
      component: () => import("@/view/error/403.vue"),
    },
    {
      // the 500 route, when access denied
      path: "/500",
      name: "500",
      component: () => import("@/view/error/500.vue"),
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/error/404.vue"),
    },
    {
      path: "/homepage",
      name: "homepage",
      component: () => import("@/view/homepage/homepage.vue"),
    },
    {
      path: "/",
      name: "app",
      children: [
        {
          path: "/s/:id",
          component: () => import("@/App.vue"),
        },
      ],
    },
    {
      path: "/policies",
      name: "policies",
      component: () => import("@/view/homepage/policies.vue"),
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import("@/view/homepage/contact.vue"),
    },
  ],
});
