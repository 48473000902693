import ApiService from "@/core/services/api.service";
import { responseError } from "@/core/mixin/shared";
import querystring from "querystring";

export const BASE_URL = "api/company";
export const RESET_POINT_URL = "api/company/reset-points";
export const SELF_URL = "api/company/self";
export const MODULE_NAME = "COMPANY";
export const LOADING = `LOADING`;
export const ERROR = `ERROR`;
export const SUCCESS = `SUCCESS`;
export const ITEM = `ITEM`;
export const SELF = `SELF`;
export const ITEMS = `ITEMS`;
export const ONLINE_USERS = `ONLINE_USERS`;

export const SET_LOADING = `M/LOADING`;
export const SET_ERROR = `M/ERROR`;
export const SET_SUCCESS = `M/SUCCESS`;
export const SET_ITEMS = `M/ITEMS`;
export const SET_ONLINE_USERS = `M/ONLINE_USERS`;
export const SET_ITEM = `M/CURRENT_ITEM`;
export const SET_SELF = `M/SELF`;
export const RESET_VALUES = `M/RESET_VALUES`;

// ACTIONS
export const GET_ITEMS = `GET_ITEMS`;
export const RESET_POINTS = `RESET_POINTS`;
export const GET_ITEM = `GET_ITEM`;
export const GET_SELF = `GET_SELF`;
export const UPDATE_ITEM = `UPDATE_ITEM`;
export const CREATE_ITEM = `CREATE_ITEM`;
export const UPDATE_SELF = `UPDATE_SELF`;
export const NEW_UPDATE_SELF = `NEW_UPDATE_SELF`;

const state = {
  items: [],
  online_users: [],
  item: {},
  self: null,
  loading: false,
  error: null,
  success: null,
};

const getters = {
  [LOADING]: (state) => {
    return state.loading;
  },
  [ERROR]: (state) => {
    return state.error;
  },
  [SUCCESS]: (state) => {
    return state.success;
  },
  [ITEMS]: (state) => {
    return state.items;
  },
  [ITEM]: (state) => {
    return state.item;
  },
  [SELF]: (state) => {
    return state.self;
  },
  [ONLINE_USERS]: (state) => {
    return state.online_users;
  },
};

const mutations = {
  [SET_LOADING]: (state, payload) => {
    state.loading = payload;
  },
  [SET_ERROR]: (state, payload) => {
    state.error = payload ? responseError(payload) : null;
  },
  [SET_ITEMS]: (state, payload) => {
    state.items = payload;
  },
  [SET_SUCCESS]: (state, payload) => {
    state.success = payload;
  },
  [SET_ITEM]: (state, payload) => {
    state.item = payload;
  },
  [SET_SELF]: (state, payload) => {
    state.self = payload;
  },
  [SET_ONLINE_USERS]: (state, payload) => {
    state.online_users = payload;
  },
  [RESET_VALUES]: (state) => {
    state.success = null;
    state.error = null;
    state.loading = false;
    state.item = null;
  },
};

const actions = {
  [GET_ITEMS]: (context, payload) => {
    let url = payload.url || BASE_URL;
    let filters = payload.filters;
    context.commit(SET_LOADING, true);
    context.commit(SET_ERROR, null);
    return ApiService.get(`${url}?` + querystring.stringify(filters))
      .then((response) => {
        context.commit(SET_LOADING, false);
        context.commit(SET_ITEMS, response.data);
        return response.data;
      })
      .catch((error) => {
        context.commit(SET_ERROR, error);
        context.commit(SET_LOADING, false);
      });
  },
  [GET_ITEM]: (context, payload) => {
    let url = payload.url || BASE_URL;
    context.commit(SET_LOADING, true);
    context.commit(SET_ERROR, null);
    return ApiService.get(url)
      .then((response) => {
        context.commit(SET_ITEM, response.data);
        context.commit(SET_LOADING, false);
        return response.data;
      })
      .catch((error) => {
        context.commit(SET_ERROR, error);
        context.commit(SET_LOADING, false);
      });
  },
  [GET_SELF]: (context, payload) => {
    let url = payload.url || SELF_URL;
    context.commit(SET_LOADING, true);
    context.commit(SET_ERROR, null);
    return ApiService.get(url)
      .then((response) => {
        context.commit(SET_SELF, response.data);
        context.commit(SET_LOADING, false);
        return response.data;
      })
      .catch((error) => {
        context.commit(SET_ERROR, error);
        context.commit(SET_LOADING, false);
      });
  },
  [UPDATE_ITEM]: (context, payload) => {
    let url = payload.url || BASE_URL;
    let contents = payload.contents;
    context.commit(SET_LOADING, true);
    context.commit(SET_ERROR, null);
    context.commit(SET_SUCCESS, null);
    return ApiService.post(url, contents)
      .then((response) => {
        context.commit(SET_LOADING, false);
        context.commit(SET_SUCCESS, true);
        context.commit(SET_ITEM, response.data);
      })
      .catch((error) => {
        context.commit(SET_ERROR, error);
        context.commit(SET_LOADING, false);
      });
  },
  [UPDATE_SELF]: (context, payload) => {
    let url = payload.url || BASE_URL;
    let contents = payload.contents;
    context.commit(SET_LOADING, true);
    context.commit(SET_ERROR, null);
    return ApiService.post(url, contents)
      .then((response) => {
        context.commit(SET_LOADING, false);
        context.commit(SET_ITEM, response.data);
      })
      .catch((error) => {
        context.commit(SET_ERROR, error);
      });
  },
  [NEW_UPDATE_SELF]: (context, payload) => {
    let url = payload.url || BASE_URL;
    let contents = payload.contents;
    context.commit(SET_LOADING, true);
    context.commit(SET_ERROR, null);

    return ApiService.post(url, contents)
      .then((response) => {
        context.commit(SET_LOADING, false);
        context.commit(SET_ITEM, response.data);
      })
      .catch((error) => {
        context.commit(SET_ERROR, error);
      });
  },
  [CREATE_ITEM]: (context, payload) => {
    let url = payload.url;
    let contents = payload.contents;
    context.commit(SET_LOADING, true);
    context.commit(SET_ERROR, null);
    return ApiService.post(url, contents)
      .then((response) => {
        context.commit(SET_LOADING, false);
        context.commit(SET_ITEM, response.data);
      })
      .catch((error) => {
        context.commit(SET_ERROR, error);
        context.commit(SET_LOADING, false);
      });
  },
  [RESET_POINTS]: (context, payload) => {
    let url = RESET_POINT_URL;
    let contents = payload.contents;
    context.commit(SET_LOADING, true);
    context.commit(SET_ERROR, null);
    return ApiService.post(url, contents)
      .then((response) => {
        context.commit(SET_LOADING, false);
      })
      .catch((error) => {
        context.commit(SET_ERROR, error);
        context.commit(SET_LOADING, false);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
