import ApiService from "@/core/services/api.service";
import { assign } from "lodash";
import { responseError } from "@/core/mixin/shared";
import router from "@/router";

import SecureLS from "secure-ls";

const ls = new SecureLS({ encodingType: "aes", isCompression: true });

// GETTERS
export const AUTH = "AUTH/AUTH";
export const USER = "AUTH/AUTH";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const ERROR = `ERROR`;
export const SEND_EMAIL = "send_email";
export const REGISTER_WITH_SIGN = "register_with_sign";
export const REGISTER = "register";
export const RESET_PASSWORD = "reset_password";
export const CREATE_PASSWORD = "create_password";
export const SEND_SMS = "send_sms";
export const LOGOUT = "logout";
export const UPDATE_USER = "updateUser";
export const GET_USER_WITH_TOKEN = "getUserWithToken";
export const LOGIN_WITH_PHONE = "loginWithPhone";
export const LOGIN_WITH_NATIONAL_ID = "loginWithNationalId";
export const LOGIN_WITH_REGISTRATION_NUMBER = "loginWithRegistrationNumber";
// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  menu: {},
  isAuthenticated: false,
};

const getters = {
  [ERROR]: (state) => {
    return state.errors;
  },
  currentUser(state) {
    return state.user;
  },
  [AUTH]: (state) => {
    return JSON.parse(ls.get("user"));
  },
  menu(state) {
    return state.user;
  },
  isAuthenticated(state) {
    try {
      return ls.get("token");
    } catch (e) {
      // window.localStorage.clear();
      return false;
    }
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve) => {
      credentials = assign({}, credentials, { grant_type: "password" });
      context.commit(SET_ERROR, null);
      ApiService.post("api/token", credentials)
        .then((response) => {
          if (+response.status === 200) {
            context.commit(SET_AUTH, response.data);
            window.localStorage.setItem("modules", response.data.modules);
          }
          resolve(response);
        })
        .catch((response) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [LOGOUT](context, credentials) {
    return new Promise((resolve) => {
      context.commit(SET_ERROR, null);
      ApiService.post("api/logout")
        .then(({ data }) => {
          context.commit(PURGE_AUTH);
        })
        .catch((response) => {
          context.commit(PURGE_AUTH);
        });
    });
  },
  [SEND_SMS](context, credentials) {
    return new Promise((resolve) => {
      credentials = assign({}, credentials, { grant_type: "password" });
      ApiService.post("api/auth-validation", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [GET_USER_WITH_TOKEN]: (context, payload) => {
    let url = payload.url;
    return ApiService.get(url, "", {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          context.commit(SET_AUTH, response.data);
        }
        return response.data;
      })
      .catch((response) => {
        context.commit(SET_ERROR, response);
      });
  },
  [LOGIN_WITH_PHONE]: (context, payload) => {
    let url = payload.url;
    return ApiService.get(`${url}`)
      .then((response) => {
        if (response.status === 200) {
          context.commit(SET_AUTH, response.data);
        }
        return response.data;
      })
      .catch((response) => {
        context.commit(SET_ERROR, response);
      });
  },
  [LOGIN_WITH_NATIONAL_ID]: (context, payload) => {
    let url = payload.url;
    return ApiService.get(`${url}`)
      .then((response) => {
        if (response.status === 200) {
          context.commit(SET_AUTH, response.data);
        }
        return response.data;
      })
      .catch((response) => {
        context.commit(SET_ERROR, response);
      });
  },
  [LOGIN_WITH_REGISTRATION_NUMBER]: (context, payload) => {
    let url = payload.url;
    return ApiService.get(`${url}`)
      .then((response) => {
        if (response.status === 200) {
          context.commit(SET_AUTH, response.data);
        }
        return response.data;
      })
      .catch((response) => {
        context.commit(SET_ERROR, response);
      });
  },
  [SEND_EMAIL](context, credentials) {
    return new Promise((resolve) => {
      credentials = assign({}, credentials);
      ApiService.post("api/forgot-password", credentials)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [RESET_PASSWORD](context, credentials) {
    return new Promise((resolve) => {
      credentials = assign({}, credentials);
      ApiService.post("api/reset-password", credentials)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [CREATE_PASSWORD](context, credentials) {
    return new Promise((resolve) => {
      credentials = assign({}, credentials);
      ApiService.post("api/create-password", credentials)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve) => {
      credentials = assign({}, credentials);
      context.commit(SET_ERROR, null);
      ApiService.post("api/register", credentials)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((response) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [REGISTER_WITH_SIGN](context, credentials) {
    return new Promise((resolve) => {
      let url = credentials.url;
      credentials = assign({}, credentials);
      ApiService.post(url, credentials)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (!ls.get("token")) {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload;
    const user = { email, username, bio, image };
    if (password) {
      user.password = password;
    }

    return ApiService.put("user", user).then(({ data }) => {
      context.commit(SET_AUTH, data);
      return data;
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = responseError(error);
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = null;
    state.menu = user.menu;
    ls.set("token", state.user.access_token);
    ls.set("refresh_token", state.user.refresh_token);
    let tempUser = {};
    Object.assign(tempUser, state.user);
    delete tempUser["menu"];
    ls.set("user", JSON.stringify(tempUser));
    ls.set("parentCompany", JSON.stringify(tempUser));
    ls.set("module", JSON.stringify(state.user.modules));
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = null;
    state.errors = null;
    delete state.user;
    ls.remove("token");
    ls.remove("refresh_token");
    ls.remove("user");
    router.push({ name: "login" }).catch((error) => {
      if (error.name !== "NavigationDuplicated") {
        throw error;
      }
    });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
