import CanvasToImage from "canvas2image";
import SecureLS from "secure-ls";

const ls = new SecureLS({ encodingType: "aes", isCompression: true });
import { getSubDomainId } from "@/core/services/store.js";
export default {
  data() {
    return {
      globalOnlineUsers: [],
    };
  },
  computed: {
    apiHeaders() {
      return {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods":
          "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.secureLocalStorage("token"),
        Company: getSubDomainId?.value,
      };
    },
    authUser() {
      return this.secureLocalStorage("user")
        ? JSON.parse(this.secureLocalStorage("user"))
        : null;
    },
    isSuperAdmin() {
      if (!(this.authUser && this.authUser.roles)) {
        return false;
      }
      let roles = this.authUser.roles;
      return roles.find((item) => item.id == 1);
    },
    isAdmin() {
      if (!(this.authUser && this.authUser.roles)) {
        return false;
      }
      let roles = this.authUser.roles;
      return roles.find((item) => item.id == 2);
    },
    isUser() {
      if (!(this.authUser && this.authUser.roles)) {
        return false;
      }
      let roles = this.authUser.roles;
      return roles.find((item) => item.id == 3);
    },
    socialPostVisible() {
      if (!(this.authUser && this.authUser.modules)) {
        return false;
      }
      let modules = JSON.parse(this.authUser.modules);
      return modules.find((item) => item == "wall");
    },
    LeaderTableVisible() {
      if (!(this.authUser && this.authUser.modules)) {
        return false;
      }
      let modules = JSON.parse(this.authUser.modules);
      return modules.find((item) => item == "leader-table");
    },
    $isGiftVisible() {
      if (!(this.authUser && this.authUser.modules)) {
        return false;
      }

      let modules = JSON.parse(this.authUser.modules);

      return modules.find((item) => item === "gift") !== undefined;
    },
    $isCatalogTrainingVisible() {
      if (!(this.authUser && this.authUser.modules)) {
        return false;
      }
      let modules = JSON.parse(this.authUser.modules);
      return modules.find((item) => item == "education-category");
    },
    CatalogTrainingVisible() {
      if (!(this.authUser && this.authUser.modules)) {
        return false;
      }
      let modules = JSON.parse(this.authUser.modules);
      return modules.find((item) => item == "education-category");
    },
    CollectionVisible() {
      if (!(this.authUser && this.authUser.modules)) {
        return false;
      }
      let modules = JSON.parse(this.authUser.modules);
      return modules.find((item) => item == "my-collection");
    },
  },
  methods: {
    turkishToUpper(string) {
      var letters = { i: "İ", ş: "Ş", ğ: "Ğ", ü: "Ü", ö: "Ö", ç: "Ç", ı: "I" };
      string = string.replace(/(([iışğüçö]))/g, function (letter) {
        return letters[letter];
      });
      return string.toUpperCase();
    },
    turkishToLower(string) {
      var letters = { İ: "i", I: "ı", Ş: "ş", Ğ: "ğ", Ü: "ü", Ö: "ö", Ç: "ç" };
      string = string.replace(/(([İIŞĞÜÇÖ]))/g, function (letter) {
        return letters[letter];
      });
      return string.toLowerCase();
    },
    secureLocalStorage(key = null) {
      return key ? ls.get(key) : ls.getAllKeys();
    },
    user() {
      return JSON.parse(this.secureLocalStorage("user"));
    },
    getIconByKey(key, configs = null) {
      if (configs) {
        return `<img 
                            src="${
                              process.env.BASE_URL + this.layoutConfig(key)
                            }" 
                            class="${
                              configs.class || "w-25px h-25px object-cover mr-2"
                            }"
                            style="${configs.style || ""}"
                        >`;
      }
      return process.env.BASE_URL + this.layoutConfig(key);
    },
    downloadImage(elementId = "image-holder", filename = "download") {
      const el = document.getElementById(elementId);
      const options = {
        type: "dataURL",
        scale: 2,
        useCORS: true,
        width: "600",
        height: "1024",
        onrendered: function (canvas) {},
      };
      this.$html2canvas(el, options).then((canvas) => {
        CanvasToImage.saveAsImage(canvas, "600px", "1024px");
      });
    },

    async downloadPdf(
      elementId = "pdf-holder",
      filename = "download",
      closeAfterDownload = true
    ) {
      // JS way
      /*
            let printContents = document.getElementById('pdf-holder').innerHTML;
            let originalContents = document.body.innerHTML;

            document.body.innerHTML = printContents;
            window.print();
            document.body.innerHTML = originalContents;
            return;
            */

      const el = document.getElementById(elementId);
      const options = {
        type: "dataURL",
        scale: 2,
        useCORS: true,
        width: el.getBoundingClientRect().width,
        height: el.getBoundingClientRect().height,
      };
      let result = await this.$html2canvas(el, options);
      const pdf = new jsPDF({
        orientation: "portrait",
      });
      const imgProps = pdf.getImageProperties(result);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(result, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(filename + ".pdf");
      if (closeAfterDownload) {
        setTimeout(function () {
          window.close();
        }, 1000);
      }
    },
    getProductTypeIconById(id) {
      let icons = {
        1: "fas fa-tshirt",
        2: "flaticon-squares",
        3: "fas fa-ring",
      };

      return icons[id.toString()];
    },
    closeModalByName(name) {
      this.$modal.hide(name);
    },
    openModalByName(name) {
      this.$modal.show(name);
    },
    showSuccessNotification() {
      let successMessage = this.$route.params.success;
      if (successMessage != null) {
        this.$notify({
          group: "notification",
          class: "bg-success",
          title: this.$t("general.success"),
          position: "bottom right",
          text: successMessage,
        });
      }
    },
    showErrorNotification(message = null) {
      let errorMessage = message || this.$route.params.error;
      if (errorMessage != null) {
        this.$notify({
          group: "notification",
          class: "bg-error",
          title: this.$t("general.error"),
          position: "bottom right",
          text: this.$t(errorMessage),
        });
      }
    },
    getLanguageById(id) {
      let languages = JSON.parse(this.secureLocalStorage("languages"));
      for (let i = 0; i < languages.length; i++) {
        if (Number(languages[i].id === Number(id))) {
          return languages[i];
        }
      }
      return null;
    },
    getValueByDottedKey(object, key) {
      let temp = { ...object };
      try {
        let splitKeys = key.split(".");
        for (let i = 0; i < splitKeys.length; i++) {
          temp = temp[splitKeys[i]];
        }
      } catch (e) {
        console.log({ object, key });
        console.error(e);
        return "";
      }
      return temp;
    },
    capitalize(string) {
      let pieces = string.split(" "),
        result = "";
      for (let i = 0; i < pieces.length; i++) {
        result +=
          pieces[i].charAt(0).toUpperCase() + pieces[i].slice(1).toLowerCase();
        if (pieces.length - 1 !== i) {
          result += " ";
        }
      }
      return result;
    },
    convertArrayToObjectByKey(array, key) {
      let result = {};
      try {
        for (let i = 0; i < array.length; i++) {
          result[array[i][key]] = array[i];
        }
        return result;
      } catch (e) {
        console.log(e);
        return {};
      }
    },
    openInNewTab(url) {
      window.open(url, "_blank");
    },
    isUserInGivenRoles(roles) {
      return roles.includes(this.user().roles[0].id);
    },
    findSameRolesByIds(roles) {
      let user = JSON.parse(this.secureLocalStorage("user"));
      let role_ids = [];
      for (let i = 0; i < user.scopes.length; i++) {
        let temp = user.scopes[i];
        if (!role_ids.includes(Number(temp.role_user_id))) {
          role_ids.push(Number(temp.role_user_id));
        }
      }
      if (!Array.isArray(roles)) {
        roles = [roles];
      }
      let existedRoles = [];
      role_ids.every((i) => {
        if (roles.includes(i)) {
          existedRoles.push(i);
        }
      });
      return existedRoles;
    },
    createFormDataForList(formData, key, data) {
      if (data === Object(data) || Array.isArray(data)) {
        for (let i in data) {
          if (i.toString() === "file_names") {
            continue;
          }
          if (data.file_names !== undefined) {
            if (data.file_names.includes(i)) {
              formData.append(key + "[" + i + "]", data[i]);
              continue;
            }
          }
          this.createFormDataForList(formData, key + "[" + i + "]", data[i]);
        }
      } else {
        formData.append(key, data);
      }
    },
    bytesToSize(bytes) {
      let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    timeZone() {
      let language = this.secureLocalStorage("language");
      if (language === "az") {
        return "Asia/Baku";
      } else {
        return "Europe/Istanbul";
      }
    },
  },
};
